import React from 'react';
import Img from 'gatsby-image';

import Modal from 'components/common/modal/Modal';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import formationVideoHook from 'hooks/formation-video/formation-video.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

import { formationVideo, formationVideo__image, formationVideo__video } from './FormationVideo.module.scss';

export default (): JSX.Element => {
  const { featured_image, vimeo_link } = formationVideoHook();
  const { node } = getFieldBySlug('home-formation-video', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />
      <Modal
        isVideo
        trigger={
          <div className={formationVideo}>
            <div className={formationVideo__image} role="presentation">
              <Img
                fluid={featured_image.localFile.childImageSharp.fluid}
                alt="Forming a limited company - what you need to know."
                fadeIn
              />
            </div>
          </div>
        }
      >
        <div className={formationVideo__video}>
          <iframe
            src={vimeo_link}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            title="Forming a limited company - what you need to know"
          />
        </div>
      </Modal>
    </>
  );
};
