import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import CompanyRichSnippet from 'components/common/rich-snippets/company/Company';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CompanyStructure from 'components/directus/company-structure/CompanyStructure';
import CtaPanel from 'components/directus/cta-panel/CtaPanel';
import NonResidentsOffer from 'components/directus/non-residents-offer/NonResidentsOffer';
import BankAdsGrid from 'components/directus/bank-ads-grid/BankAdsGrid';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import FourSteps from 'components/directus/four-steps/FourSteps';
import FormationVideo from 'components/directus/formation-video/FormationVideo';
import Navigation from 'components/directus/navigation/Navigation';
import Hero from 'components/directus/hero/Hero';
import HeroBoxes from 'components/directus/hero-boxes/HeroBoxes';
import HeroNamecheck from 'components/directus/hero-namecheck/HeroNamecheck';
import HomepageFaqs from 'components/directus/homepage-faqs/HomepageFaqs';
import PackageGridHomePage from 'components/directus/package-grid/home';
import RegisteredOffices from 'components/directus/registered-offices/RegisteredOffices';
import UniqueSellingPoints from 'components/directus/unique-selling-points/UniqueSellingPoints';
import WhyChooseUs from 'components/directus/why-choose-us/WhyChooseUs';
import BlogPosts from 'components/wordpress/blog-posts/BlogPosts';
import RecentReviews from 'components/directus/recent-reviews/RecentReviews';
import WhyUseAgent from 'components/directus/why-use-agent/WhyUseAgent';
import CaseStudyMosaic from 'components/directus/case-study-mosaic/CaseStudyMosaic';
import CaseStudyVideo from 'components/directus/case-study-video/CaseStudyVideo';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'home';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <meta name="google-site-verification" content="" />
        <link rel="canonical" href={node.canonical} />
      </Helmet>
      <Hero slug={slug}>
        <Navigation isHome />
        <HeroNamecheck slug={slug} />
        <HeroBoxes slug={slug} />
        <CompanyRichSnippet />
      </Hero>
      <UniqueSellingPoints slug={slug} />
      <>
        <PackageGridHomePage />
        <Wrapper>
          <Section isHome>
            <CaseStudyVideo slug="chalk-and-chilli" />
          </Section>
          <Section isHome>
            <BankAdsGrid isHome />
          </Section>
          <Section isHome>
            <CaseStudyMosaic />
          </Section>
          <Section isHome>
            <FourSteps />
          </Section>
          <Section isHome>
            <FormationVideo />
          </Section>
          <Section isHome>
            <NonResidentsOffer />
          </Section>
          <Section isHome>
            <WhyUseAgent />
          </Section>
          <Section isHome>
            <CustomerReviews />
          </Section>
          <Section isHome>
            <RecentReviews />
          </Section>
          <Section isHome>
            <WhyChooseUs />
          </Section>
          <Section isHome>
            <RegisteredOffices />
          </Section>
          <Section isHome>
            <CompanyStructure />
          </Section>
          <Section isHome>
            <CtaPanel slug="home-cta" />
          </Section>
          <Section isHome>
            <HomepageFaqs slug={slug} />
          </Section>
          <Section isHome>
            <BlogPosts />
          </Section>
        </Wrapper>
      </>
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
