import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusCaseStudy, CaseStudy } from './case-study.interface';

export default (): CaseStudy[] => {
  const { allDirectusCaseStudy } = useStaticQuery<AllDirectusCaseStudy>(graphql`
    query {
      allDirectusCaseStudy {
        edges {
          node {
              slug
              company_name
              company_owner
              video_link
              sort
              video_banner {
                data{
                    full_url
                  }
                localFile {
                  childImageSharp {
                    fluid(toFormat: WEBP, maxWidth: 1170) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              excerpt
              image {
                data {
                  full_url
                }
                width
                height
                localFile {
                  childImageSharp {
                    fluid(toFormat: WEBP, maxHeight: 700, maxWidth: 700) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
          }
        }
      }
    }
  `);

  return allDirectusCaseStudy.edges;
};
